import { useState } from 'react';
import MyCoolCodeBlock from './MyCoolCodeBlock';
import ReactDOM from 'react-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Test() {

    const [value, setValue] = useState(""); 
    const [copied, setCopied] = useState("");



    const [productTeaser, setproductTeaser] = useState("");
    const [productName, setproductName] = useState("");
    const [productDetail, setproductDetail] = useState("");
    const [productDetail2, setproductDetail2] = useState("");
    const [productDescription, setproductDescription] = useState("");
    const [productDescriptionDisplayText, setproductDescriptionDisplayText] = useState("");


    function replaceNewLines(text) {
        while(text.includes("\n",0)){
            text =text.replace("\n", "</br>")
          }

        return text;
    }


    var test = '<div class="product-teaser">\n' + productTeaser + "\n</div>\n" + '<div class="product-name">\n<h1>' + productName + '</h1>\n</div>\n' + '<div class="product-detail">\n' + productDetail + '\n</div>\n' + '<div class="product-detail-second">\n' + productDetail2 + '\n</div>\n' + '<div class="product-description">\n' + productDescription + ' \n</div>'
    return (
        <div       style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            paddingTop: '100px'
          }}>
            <div>
                <form>
                    <label>Spitzname:
                        <input
                            type="text"
                            value={productTeaser}
                            onChange={(e) => setproductTeaser(e.target.value)}
                        />
                    </label> <br /> <br />
                    <label>Produkt Name:
                        <input
                            type="text"
                            value={productName}
                            onChange={(e) => setproductName(e.target.value)}
                        />
                    </label> <br /> <br />
                    <label>Produkt Detail:
                        <input
                            type="text"
                            value={productDetail}
                            onChange={(e) => setproductDetail(e.target.value)}
                        />
                    </label>
                    <br /> <br />
                    <label>Produkt Detail 2:
                        <input
                            type="text"
                            value={productDetail2}
                            onChange={(e) => setproductDetail2(e.target.value)}
                        />
                    </label>
                    <br /> <br />
                    <label>Produkt Beschreibung:
                        <textarea
                            type="text"
                            value={productDescriptionDisplayText}
                            onChange={(e) => {
                                setproductDescriptionDisplayText(e.target.value)
                                setproductDescription(replaceNewLines(e.target.value))}}

                        />
                    </label>

                </form>

        <CopyToClipboard text={test}>
          <button>Kopieren</button>
        </CopyToClipboard>

        <button onClick={() => {
            setproductTeaser("");
            setproductName("")
            setproductDetail("")
            setproductDetail2("")
            setproductDescription("")
            setproductDescriptionDisplayText("")
        }}>Felder leeren</button>

               {/*< MyCoolCodeBlock code={test} showLineNumbers={false} /> */}
            </div>
        </div>
    )
}

export default Test;

