import logo from './logo.svg';
import './App.css';
import Test from "./components/test"

function App() {
  return (
    <div className="App" style={{display: 'flex', flexDirection: 'row'}}>
        <Test />
    </div>
  );
}

export default App;
